/* eslint-disable default-case */
import { useCallback, useEffect, useReducer } from 'react';

import {
  ConfirmationAction,
  ConfirmationState,
  CONFIRMATION_ACTION_TYPE,
  CONFIRMATION_STATUS,
  CONFIRMATION_TYPE,
  ERROR_TYPE,
  PickConfirmationQueryParams,
  PickConfirmationQueryReturnType,
  UseConfirmationReturnType,
  UseConfirmationState,
} from './useConfirmation.decl';

import { PasswordFormValues } from '@components/PasswordForm';
import { HttpError, NetworkError } from '@helpers/errors.helpers';
import { useQueryParams } from '@hooks/useQueryParams';
import { sendConfirmInvitation } from '@services/auth';
import { CognitoTokenResponse, ConfirmInvitationResponse } from '@services/auth.decl';

/**
 * Reducer holding the logic around the confirmation process.
 * @param initialState - initial state of the confirmation
 * @param action - action to be dispatched
 * @returns the new state
 */
export function reducer(
  initialState: ConfirmationState,
  action: ConfirmationAction
): ConfirmationState {
  switch (initialState.status) {
    case CONFIRMATION_STATUS.CHECKING_TOKEN:
      switch (action.type) {
        case CONFIRMATION_ACTION_TYPE.TOKEN_CHECK_FAILED:
          return { status: CONFIRMATION_STATUS.ERROR, error: action.error };
        case CONFIRMATION_ACTION_TYPE.TOKEN_CHECK_SUCCEEDED:
          return { status: CONFIRMATION_STATUS.IDLE };
      }
      break;

    case CONFIRMATION_STATUS.IDLE:
    case CONFIRMATION_STATUS.ERROR:
      switch (action.type) {
        case CONFIRMATION_ACTION_TYPE.PASSWORD_SUBMITTED:
          return { status: CONFIRMATION_STATUS.LOADING };
      }
      break;

    case CONFIRMATION_STATUS.LOADING:
      switch (action.type) {
        case CONFIRMATION_ACTION_TYPE.SUBMIT_FAILED:
          return { status: CONFIRMATION_STATUS.ERROR, error: action.error };
        case CONFIRMATION_ACTION_TYPE.SUBMIT_SUCCEEDED:
          return {
            status: CONFIRMATION_STATUS.SUCCESS,
            ...(action.user && { user: action.user }),
            ...(action.tokenResponse && { tokenResponse: action.tokenResponse }),
          };
      }
      break;
  }

  return initialState;
}

/**
 * Checks token validity.
 * @param token - Token coming from the query string
 * @param validityLimit - Validity limit of the token coming form the query string
 */
function checkTokenValidity(token: string | null, validityLimit: string | null): ERROR_TYPE | null {
  if (!token) {
    return ERROR_TYPE.EMPTY_TOKEN;
  }
  if (!validityLimit || parseInt(validityLimit, 10) * 1000 < new Date().getTime()) {
    return ERROR_TYPE.INVITATION_EXPIRED;
  }
  return null;
}

/**
 * Return the right error type according to the given exception.
 * @param error - error following a call to the backend
 * @returns the right error type
 */
function getErrorType(error: Error): ERROR_TYPE {
  // The format we receive the error from the backend is inconsistent, we sometimes receive "errors", sometimes not.
  // We need to ensure that the key exists before doing our mapping.
  if (error instanceof HttpError && error.body.errors) {
    if (
      Array.isArray(error.body.errors.invitation_token) &&
      error.body.errors.invitation_token.includes('is_invalid')
    ) {
      return ERROR_TYPE.INVITATION_EXPIRED;
    }
    if (Array.isArray(error.body.errors.password)) {
      if (error.body.errors.password.includes('too_short')) {
        return ERROR_TYPE.PASSWORD_TOO_SHORT;
      }
      if (error.body.errors.password.includes('password_format')) {
        return ERROR_TYPE.PASSWORD_FORMAT;
      }
    }
  }

  if (error instanceof NetworkError) {
    return ERROR_TYPE.NETWORK;
  }

  return ERROR_TYPE.UNKNOWN;
}

/**
 * Given an invitation type, it will transform the query parameters to fit the right interface supported by the endpoint.
 * @param pickConfirmationQueryParamsArgs - destructured parameter
 * @returns an object with the right shape
 */
export function pickConfirmationQueryParams({
  type,
  searchParams,
}: PickConfirmationQueryParams): PickConfirmationQueryReturnType {
  if (type === CONFIRMATION_TYPE.INVITATION) {
    return {
      token: searchParams.get('confirm_invitation_token'),
      validityLimit: searchParams.get('validity_limit'),
      email: searchParams.get('email'),
    };
  }

  if (type === CONFIRMATION_TYPE.USER) {
    return {
      token: searchParams.get('confirmation_token'),
      validityLimit: searchParams.get('expirationDate'),
      email: searchParams.get('email'),
    };
  }

  return {
    token: null,
    validityLimit: null,
    email: null,
  };
}

/**
 * Hook handling invitation confirmation logic given a confirmation type.
 * @param type - type of the confirmation
 * @returns a tuple holding states and the handler function
 */
export function useConfirmation(type?: CONFIRMATION_TYPE): UseConfirmationReturnType {
  const { searchParams } = useQueryParams();
  const [state, dispatch] = useReducer(reducer, { status: CONFIRMATION_STATUS.CHECKING_TOKEN });

  // We get the token and the validity limit coming from the query string
  const { token, validityLimit, email } = pickConfirmationQueryParams({ type, searchParams });

  useEffect(() => {
    // Checks the token validity when the component is mounted
    const error = checkTokenValidity(token, validityLimit);
    dispatch(
      error
        ? { type: CONFIRMATION_ACTION_TYPE.TOKEN_CHECK_FAILED, error }
        : { type: CONFIRMATION_ACTION_TYPE.TOKEN_CHECK_SUCCEEDED }
    );
  }, [token, validityLimit]);

  const handleSubmit = useCallback(
    async ({ password, passwordConfirmation }: PasswordFormValues) => {
      // We put the UI in loading state
      dispatch({ type: CONFIRMATION_ACTION_TYPE.PASSWORD_SUBMITTED });

      try {
        // We call the backend to define user's password
        const res = await sendConfirmInvitation({
          password,
          passwordConfirmation,
          email,
          invitationToken: token,
        });

        // We receive an auth token, and sign-in user
        if ('idToken' in res) {
          const tokenResponse = res as CognitoTokenResponse;
          dispatch({ type: CONFIRMATION_ACTION_TYPE.SUBMIT_SUCCEEDED, tokenResponse });
          return;
        }

        // We put the UI in success state
        const user = res as ConfirmInvitationResponse;
        dispatch({
          type: CONFIRMATION_ACTION_TYPE.SUBMIT_SUCCEEDED,
          user,
        });
      } catch (e) {
        // We put the UI error state
        dispatch({
          type: CONFIRMATION_ACTION_TYPE.SUBMIT_FAILED,
          error: getErrorType(e as Error),
        });
      }
    },
    [email, token]
  );

  return [
    handleSubmit,
    {
      loading: state.status === CONFIRMATION_STATUS.LOADING,
      error: state.status === CONFIRMATION_STATUS.ERROR ? state.error : undefined,
      user: state.status === CONFIRMATION_STATUS.SUCCESS ? state.user : undefined,
      tokenResponse: state.status === CONFIRMATION_STATUS.SUCCESS ? state.tokenResponse : undefined,
      succeeded: state.status === CONFIRMATION_STATUS.SUCCESS,
    } as UseConfirmationState,
  ];
}
