import { useEffect } from 'react';

import { useConfirmation } from './hooks/useConfirmation';
import { CONFIRMATION_TYPE, ERROR_TYPE } from './hooks/useConfirmation.decl';
import { ERROR_MESSAGES_MAPPER } from './mapper';

import { LoginLayout } from '@components/LoginLayout/LoginLayout';
import { PasswordForm } from '@components/PasswordForm';
import { LOGIN_ROUTE } from '@constants/routes.constants';
import { AGENT_SETUP_GUIDE_URL } from '@constants/urls.constants';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { AUTHENTICATION_STATUS } from '@state/app/authentication/authentication.decl';
import noop from 'lodash-es/noop';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate } from 'react-router-dom';

export const ANIMATION_DURATION = 2000;
interface ConfirmParams {
  type: CONFIRMATION_TYPE;
  'data-test'?: string;
}

export function Confirm({ type, 'data-test': dataTest }: ConfirmParams) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [confirmInvitation, { loading, user, error, tokenResponse, succeeded }] =
    useConfirmation(type);
  const {
    authState: { status },
    actions: { setSignInSuccess },
  } = useAuthenticationState();

  const errorKey = error && ERROR_MESSAGES_MAPPER[error];
  const errorMessage = errorKey && t(errorKey);

  // redirects users to the corresponding page
  useEffect(() => {
    if (!succeeded) {
      return noop;
    }

    const timerId = window.setTimeout(() => {
      if (user) {
        // if the users belong to admin, redirect them to the login page
        if (user.isAdmin) {
          navigate(LOGIN_ROUTE);
        } else {
          // otherwise, redirect them to the agent setup page
          window.location.replace(AGENT_SETUP_GUIDE_URL);
        }
      }

      if (tokenResponse) {
        setSignInSuccess({ response: tokenResponse });
      }
    }, ANIMATION_DURATION);

    return () => {
      clearTimeout(timerId);
    };
  }, [user, navigate, tokenResponse, succeeded, setSignInSuccess]);

  useEffect(() => {
    if (status === AUTHENTICATION_STATUS.AUTHENTICATED) {
      navigate('/', { state: { fromPasswordScreen: true } });
    }
  }, [navigate, status]);

  /**
   * Redirect users to the login page if the invitation token is empty
   */
  if (error === ERROR_TYPE.EMPTY_TOKEN) {
    return <Navigate to={LOGIN_ROUTE} />;
  }

  return (
    <LoginLayout data-test={dataTest}>
      <PasswordForm
        title={t('confirm_invitation.title')}
        errorMessage={errorMessage}
        success={succeeded}
        onSubmit={succeeded ? noop : confirmInvitation}
        disabled={loading || error === ERROR_TYPE.INVITATION_EXPIRED}
      />
    </LoginLayout>
  );
}
