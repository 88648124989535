import { PasswordFormValues } from '@components/PasswordForm';
import { CognitoTokenResponse, ConfirmInvitationResponse } from '@services/auth.decl';

export enum CONFIRMATION_STATUS {
  CHECKING_TOKEN = 'CHECKING_TOKEN',
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum ERROR_TYPE {
  EMPTY_TOKEN = 'EMPTY_TOKEN',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_FORMAT = 'PASSWORD_FORMAT',
  NETWORK = 'NETWORK',
  UNKNOWN = 'UNKNOWN',
}

export enum CONFIRMATION_TYPE {
  INVITATION = 'invitation',
  USER = 'user',
}

export interface PickConfirmationQueryParams {
  searchParams: URLSearchParams;
  type?: CONFIRMATION_TYPE;
}
export interface PickConfirmationQueryReturnType {
  token: string | null;
  validityLimit: string | null;
  email: string | null;
}

export interface ConfirmationCheckingTokenState {
  status: CONFIRMATION_STATUS.CHECKING_TOKEN;
}

export interface ConfirmationIdleState {
  status: CONFIRMATION_STATUS.IDLE;
}

export interface ConfirmationLoadingState {
  status: CONFIRMATION_STATUS.LOADING;
}

export interface ConfirmationSuccessState {
  status: CONFIRMATION_STATUS.SUCCESS;
  user?: ConfirmInvitationResponse;
  tokenResponse?: CognitoTokenResponse;
}

export interface ConfirmationErrorState {
  status: CONFIRMATION_STATUS.ERROR;
  error: ERROR_TYPE;
}

export type ConfirmationState =
  | ConfirmationCheckingTokenState
  | ConfirmationIdleState
  | ConfirmationLoadingState
  | ConfirmationSuccessState
  | ConfirmationErrorState;

export enum CONFIRMATION_ACTION_TYPE {
  TOKEN_CHECK_FAILED = 'TOKEN_CHECK_FAILED',
  TOKEN_CHECK_SUCCEEDED = 'TOKEN_CHECK_SUCCEEDED',
  PASSWORD_SUBMITTED = 'PASSWORD_SUBMITTED',
  SUBMIT_FAILED = 'SUBMIT_FAILED',
  SUBMIT_SUCCEEDED = 'SUBMIT_SUCCEEDED',
}

export interface ConfirmationTokenCheckFailedAction {
  type: CONFIRMATION_ACTION_TYPE.TOKEN_CHECK_FAILED;
  error: ERROR_TYPE;
}

export interface ConfirmationTokenCheckSucceededAction {
  type: CONFIRMATION_ACTION_TYPE.TOKEN_CHECK_SUCCEEDED;
}

export interface ConfirmationPasswordSubmittedAction {
  type: CONFIRMATION_ACTION_TYPE.PASSWORD_SUBMITTED;
}

export interface ConfirmationSubmitFailedAction {
  type: CONFIRMATION_ACTION_TYPE.SUBMIT_FAILED;
  error: ERROR_TYPE;
}

export interface ConfirmationSubmitSucceededAction {
  type: CONFIRMATION_ACTION_TYPE.SUBMIT_SUCCEEDED;
  user?: ConfirmInvitationResponse;
  tokenResponse?: CognitoTokenResponse;
}

export type ConfirmationAction =
  | ConfirmationTokenCheckFailedAction
  | ConfirmationTokenCheckSucceededAction
  | ConfirmationPasswordSubmittedAction
  | ConfirmationSubmitFailedAction
  | ConfirmationSubmitSucceededAction;

export type UseConfirmationState =
  | {
      loading: true;
      error: undefined;
      user: undefined;
      succeeded: false;
      tokenResponse: undefined;
    }
  | {
      loading: false;
      error: undefined;
      user?: ConfirmInvitationResponse;
      tokenResponse?: CognitoTokenResponse;
      succeeded: true;
    }
  | {
      loading: false;
      error: ERROR_TYPE;
      user: undefined;
      succeeded: false;
      tokenResponse: undefined;
    };

export type UseConfirmationReturnType = [
  ({ password, passwordConfirmation }: PasswordFormValues) => Promise<void>,
  UseConfirmationState
];
